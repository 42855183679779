import { EntityConfig, entityCoreFormFields } from '@shapeable/core';
import { EntityTypeName, HorizonType as MyType } from '@shapeable/types';
const gql = String.raw;

export const ProductIndustry: EntityConfig<MyType> = {
  name: 'ProductIndustry',
  type: 'child',
  autoRoutes: ['create', 'update'],
  form: {
    fields: {
      ...entityCoreFormFields,
      product: { type: 'linked', entityTypeName: 'Product', required: true, variant: 'forIndustry' },
      industry: { type: 'linked', entityTypeName: 'Industry', required: true, variant: 'forProduct' },
      description: { type: 'longText' },
    }
  }
}; 