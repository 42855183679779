import { createApplication, entityConfigs } from '@shapeable/core';
import { themes } from '@shapeable/theme';
import { ApplicationLogo, ApplicationLogoInverted } from './components';

import { ProductIndustry, IndustryGroup } from './config/';

const theme = themes.LEARN_THEME;


const { Page, Slice, Industry, Product, Benefit } = entityConfigs;

if (Page) {
  Page.form.fields.products = { type: 'linkedMultiple', entityTypeName: 'Product', fieldSet: "library", recommended: false, description: 'Are there any {{PRODUCTS}} related to this {{PAGE}}.' };
}

if (Slice) {
  Slice.form.fields.products = { type: 'linkedMultiple', entityTypeName: 'Product', fieldSet: "library", recommended: false, description: 'Are there any {{PRODUCTS}} related to this {{PAGE}}.' };
  Slice.form.fields.benefits = { type: 'linkedTable', entityTypeName: 'Benefit', fieldSet: "overview", recommended: false, description: 'Are there any {{BENEFITS}} for this {{SLICE}}.' };
}

if (Product) {
  Product.form.fields.iconImage = { type: 'linked', label: 'Icon Image', entityTypeName: 'ImageAsset', fieldSet: "overview", recommended: false, description: 'Specify an icon image to display in {{PRODUCT}} banners and cards.' };
  Product.form.fields.industryGroups = { type: 'linkedMultiple', entityTypeName: 'IndustryGroup',  description: 'Which {{INDUSTRY_GROUPS}} are covered by this {{PRODUCT}}?' };
  Product.form.fields.productIndustries = { type: 'linkedTable', entityTypeName: 'ProductIndustry', fieldSet: "admin", recommended: false, description: 'Which specific {{INDUSTRIES}} within {{INDUSTRY_GROUPS}} are covered by this {{PRODUCT}}?' };
  Product.form.fields.benefits = { type: 'linkedTable', entityTypeName: 'Benefit', fieldSet: "overview", recommended: false, description: 'What are the {{BENEFITS}} for this {{PRODUCT}}?' };
  Product.form.fields.backgroundColor = { type: 'linked', recommended: false, label: 'Background Color', entityTypeName: 'Color', fieldSet: 'overview' };
  Product.form.fields.color = { type: 'linked', recommended: false, entityTypeName: 'Color', fieldSet: 'overview' };
}

if (Industry) {
  Industry.form.fields.dataSetId = { type: 'text', recommended: false };
  Industry.form.fields.parent = { type: 'parent', recommended: false };
}

if (Benefit) {
  Benefit.table.fieldNames = ["title", "description", "type"];
  Benefit.form.fields.description = { type: 'longText' };


  Benefit.table.fields = {
    title: { weight: 2  },
    description: { weight: 3  },
    type: { weight: 1  },
  };
  
}

const myEntityConfigs = {
  ...entityConfigs,
  ProductIndustry,
  IndustryGroup,
}

export const application = createApplication({
  theme,
  entityConfigs: myEntityConfigs,
  options: {
    systemsMapping: 'full',
  },
  components: {
    Logo: ApplicationLogo,
    LogoInverted: ApplicationLogoInverted,
  },
});

/* ---- Patch Entities definitions afterwards to ensure sub-props are already set --- */

const entities = application.entities;
