import { EntityConfig, entityStandardFormFields } from '@shapeable/core';
import { EntityTypeName, HorizonType as MyType } from '@shapeable/types';
const gql = String.raw;

export const IndustryGroup: EntityConfig<MyType> = {
  name: 'IndustryGroup',
  type: 'taxonomy',
  autoRoutes: ['create', 'update', 'list'],
  form: {
    fields: {
      ...entityStandardFormFields,
      description: { type: 'longText' },
      banner: { type: 'linked', entityTypeName: 'Banner' },
    }
  }
}; 